import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Motion_Detection/INSTAR_Cloud/NavButtons';
import ForumBox from 'components/Motion_Detection/INSTAR_Cloud/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR Cloud - Delete Account",
  "path": "/Motion_Detection/INSTAR_Cloud/Delete_Account/",
  "dateChanged": "2022-11-27",
  "author": "Mike Polinowski",
  "excerpt": "To delete your account and all associated recordings, please follow the following instructions.",
  "image": "./MD_SearchThumb_Cloud_Administration.png",
  "social": "/images/Search/MD_SearchThumb_Cloud_Administration.webp",
  "toc": "/images/Search/TOC_Icons/TOC_Icons_WebUI_Cloud.webp",
  "chapter": "Motion Detection"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='INSTAR Cloud - Delete Account' dateChanged='2022-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='To delete your account and all associated recordings, please follow the following instructions.' image='/images/Search/MD_SearchThumb_Cloud_Administration.png' twitter='/images/Search/MD_SearchThumb_Cloud_Administration.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/INSTAR_Cloud/Account_Loeschen/' locationFR='/fr/Motion_Detection/INSTAR_Cloud/Delete_Account/' crumbLabel="Delete" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "instar-cloud",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#instar-cloud",
        "aria-label": "instar cloud permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR Cloud`}</h2>
    <h3 {...{
      "id": "delete-your-account",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#delete-your-account",
        "aria-label": "delete your account permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Delete your Account`}</h3>
    <p>{`To delete your account and all associated recordings, please follow the instructions below:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d6600209b8713f62eb8c3104ae53f843/8b640/INSTAR_Cloud_Delete_Account_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAABm0lEQVQoz31Sy3KDMAzk53vvz/TSW/ru5NBLp52mmSSHFJJAEsAYDLa0HTkxJRyqGQ0YrNXuStHj/R1enh4gQURg5j4lxu8Ao3GMyjK0Y+StQ+YA/EyB3Tui5KiQ6Q5prtA0NZSq0DQNlFLQpsW+sahaK2jo46Ipn87Ogskhup7ucPWc4mZegoxGUZbQWqNSCp21sESgc3Fd176RtbZn7Jw7gZ/P0WKxRFkWKIoCm80GxhgvXYrW6zX2+31vRxzHvlkIqZEGQ2ui5XKJw+GAsiw9oLAIF5Ik6QsEME1TDxi8JnIYhgeczWb4iWPvm1zsJZzlSAbALMv8vTAkUVFr7Yeku5M1kUgShoHZfxGa/flHADl8HTtMty0cMSLxIc9zVFXl5cm56zrffZxDaQGYGDgaQtGeGQqYpHgjcsJTUlhLyjfJfkdPy+LZGUt4TQzedi0sjQClWJgG73i4e4GdDGQ1A/IDxn95yFDkypS32603f7xvF+yaGjz/BD/egtsaZI3PC8DhugyZXYCNJPP8A7yYgL9vwKsJ2HX4BaYrpWUez/H6AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d6600209b8713f62eb8c3104ae53f843/e4706/INSTAR_Cloud_Delete_Account_01.avif 230w", "/en/static/d6600209b8713f62eb8c3104ae53f843/d1af7/INSTAR_Cloud_Delete_Account_01.avif 460w", "/en/static/d6600209b8713f62eb8c3104ae53f843/7f308/INSTAR_Cloud_Delete_Account_01.avif 920w", "/en/static/d6600209b8713f62eb8c3104ae53f843/e1c99/INSTAR_Cloud_Delete_Account_01.avif 1380w", "/en/static/d6600209b8713f62eb8c3104ae53f843/66e41/INSTAR_Cloud_Delete_Account_01.avif 1454w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d6600209b8713f62eb8c3104ae53f843/a0b58/INSTAR_Cloud_Delete_Account_01.webp 230w", "/en/static/d6600209b8713f62eb8c3104ae53f843/bc10c/INSTAR_Cloud_Delete_Account_01.webp 460w", "/en/static/d6600209b8713f62eb8c3104ae53f843/966d8/INSTAR_Cloud_Delete_Account_01.webp 920w", "/en/static/d6600209b8713f62eb8c3104ae53f843/445df/INSTAR_Cloud_Delete_Account_01.webp 1380w", "/en/static/d6600209b8713f62eb8c3104ae53f843/7017d/INSTAR_Cloud_Delete_Account_01.webp 1454w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d6600209b8713f62eb8c3104ae53f843/81c8e/INSTAR_Cloud_Delete_Account_01.png 230w", "/en/static/d6600209b8713f62eb8c3104ae53f843/08a84/INSTAR_Cloud_Delete_Account_01.png 460w", "/en/static/d6600209b8713f62eb8c3104ae53f843/c0255/INSTAR_Cloud_Delete_Account_01.png 920w", "/en/static/d6600209b8713f62eb8c3104ae53f843/b1001/INSTAR_Cloud_Delete_Account_01.png 1380w", "/en/static/d6600209b8713f62eb8c3104ae53f843/8b640/INSTAR_Cloud_Delete_Account_01.png 1454w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d6600209b8713f62eb8c3104ae53f843/c0255/INSTAR_Cloud_Delete_Account_01.png",
              "alt": "INSTAR Cloud - Delete Account",
              "title": "INSTAR Cloud - Delete Account",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Open your web browser and visit the website `}<a parentName="li" {...{
          "href": "https://cloud.instar.de"
        }}>{`cloud.instar.de`}</a>{`.`}</li>
      <li parentName="ol">{`Log in to your INSTAR Cloud account using your login credentials.`}</li>
      <li parentName="ol">{`Navigate to the `}<strong parentName="li">{`Administration`}</strong>{` tab, usually located in the top navigation bar.`}</li>
      <li parentName="ol">{`Click on the `}<strong parentName="li">{`Account`}</strong>{` tab to access your account settings.`}</li>
      <li parentName="ol">{`Scroll down to the bottom of the page. There, you will find the `}<strong parentName="li">{`Delete account permanently`}</strong>{` button. Click on it.`}</li>
      <li parentName="ol">{`A confirmation message will appear, informing you that deleting the account will irreversibly delete all data, including all recordings. Ensure that you genuinely want to perform this action.`}</li>
      <li parentName="ol">{`If you are certain, click on `}<strong parentName="li">{`Delete account permanently`}</strong>{`.`}</li>
    </ol>
    <p>{`Once you have completed this process, your INSTAR Cloud account, along with all stored recordings, will be deleted. Please note that this action is irreversible and cannot be undone. Therefore, make sure to backup any important data you may need beforehand.`}</p>
    <p>{`We hope this guide is helpful to you. If you have any further questions, please feel free to contact us. `}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      